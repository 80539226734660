<template>
  <div>
    <!-- <div v-if="itemsDisplayed?.length === 0" class="alert">
      <span class="svg-round">
        <font-awesome-icon icon="triangle-exclamation" />
      </span>
      <span>Seleziona un'azienda per visualizzare e aggiungere le campagne</span>
    </div> -->

    <div :key="'accordion_included_'+exceptions" class="campaignProjectTable accordion">
      <div class="accordion-item" :class="{'item-is-open': isOpen}">
        <div v-if="!finalPreview" class="accordion-header" @click.stop.prevent="isOpen = !isOpen">
          <div class="accordion-header-content-title">
            <div class="accordion-header-title">
              <span v-if="isEdit">{{ itemsDisplayed?.length === 1 ? '1 campagna' : itemsDisplayed?.length + ' campagne' }}</span>
              <span v-else-if="!finalPreview">Campagne di {{ campaignTitle }}</span>
            </div>
            <!-- <button v-if="exceptions">
              Aggiungi eccezione
            </button> -->
            <svg v-show="displayedItems.length > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
          </div>
        </div>
        <div :class="['accordion-content', {'accordion-open': isOpen }]" @transitionend="onTransitionEnd">
          <div class="accordion-content-header">
            <div :key="'checkbox-container_'+counter" class="checkbox-container">
              <div v-if="isEdit || !finalPreview" @click.prevent.stop="correntiComputed = !correntiComputed">
                <input v-model="correntiComputed" class="correnti" type="checkbox" :checked="correntiComputed">
                <span>Correnti</span>
              </div>
              <div v-if="isEdit || !finalPreview" @click.prevent.stop="archiviateComputed = !archiviateComputed">
                <input v-model="archiviateComputed" class="archiviate" type="checkbox" :checked="archiviateComputed">
                <span>Archiviate</span>
              </div>
              <div v-if="isEdit || !finalPreview" @click.prevent.stop="scaduteComputed = !scaduteComputed">
                <input v-model="scaduteComputed" class="scadute" type="checkbox" :checked="scaduteComputed">
                <span>Scadute</span>
              </div>
            </div>
            <div class="campaign-search">
              <input v-if="!isEdit && !finalPreview" v-model="searchCoidOrTitle" type="text" placeholder="Cerca per coid o titolo campagna">
              <input v-if="isEdit || finalPreview" v-model="searchQuery" type="text" placeholder="Cerca">
              <font-awesome-icon v-show="searchCoidOrTitle || searchQuery" icon="xmark" class="xmark" @click="searchCoidOrTitle = ''; searchQuery = ''" />
            </div>
          </div>
          <div class="scrollable-content">
            <table cellpadding="0" cellspacing="0" class="table">
              <thead>
                <tr>
                  <th>
                    <span v-if="isEdit">
                      Attiva
                    </span>
                    <span v-else style="opacity: 0;">Select</span>
                  </th>
                  <th class="orderable" @click="sortTableBy('cid')">
                    <span><span>Cid</span>
                      <font-awesome-icon v-if="sortColumn === 'cid'" :icon="sortDirection === 'asc' ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                    </span>
                  </th>
                  <th class="orderable" @click="sortTableBy('title')">
                    <span><span>Titolo</span>
                      <font-awesome-icon v-if="sortColumn === 'title'" :icon="sortDirection === 'asc' ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
                    </span>
                  </th>
                  <th>Stato</th>
                  <th>Creazione</th>
                  <th v-if="finalPreview" class="orderable" @click="sortTableBy('coid')">
                    <span><span>Coid</span>
                      <font-awesome-icon v-if="sortColumn === 'coid'" :icon="sortDirection === 'asc' ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                    </span>
                  </th>
                  <th v-if="finalPreview" class="orderable" @click="sortTableBy('company')">
                    <span><span>Azienda</span>
                      <font-awesome-icon v-if="sortColumn === 'company'" :icon="sortDirection === 'asc' ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
                    </span>
                  </th>
                  <th v-if="isEdit">
                    Cancella
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in displayedItems" :key="item.cid">
                  <td>
                    <div class="material-checkbox" :class="{'removed-checkbox': exceptions}">
                      <input
                        type="checkbox"
                        :checked="isChecked(item)"
                        @click="toggleSelection($event.target.checked, item)"
                      >
                    </div>
                  </td>
                  <td>{{ item.cid }}</td>
                  <td>{{ item.titolo }}</td>
                  <td>
                    <span class="status-dot" :class="item.status === 1 ? 'corrente' : item.status === 2 ? 'scaduta' : 'archiviata'" />
                  </td>
                  <td>{{ formatDate(item.data_creazione) }}</td>
                  <td v-if="finalPreview">
                    {{ item.coid }}
                  </td>
                  <td v-if="finalPreview">
                    {{ item.company }}
                  </td>
                  <td v-if="isEdit">
                    <font-awesome-icon
                      class="deleteItem"
                      :class="{'toDelete': item.delete}"
                      icon="trash-can"
                      @click="removeItem(item.coid, item.cid)"
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="totalPages > 1" class="pagination">
                  <td colspan="100%" style="width: 100%;">
                    <div>
                      <span>{{ getPageLabel }}</span>
                      <v-pagination v-if="!finalPreview" v-model="pageNr" :pages="totalPagesItems" active-color="#bcde42" />
                      <v-pagination v-else v-model="currentPage" :pages="totalPages" active-color="#bcde42" />
                    </div>
                  </td>
                </tr>
                <tr v-if="!finalPreview && !exceptions">
                  <td colspan="100%" style="width: 100%;">
                    <material-button text="Conferma la selezione" class="pure-material-button-contained info small" :disabled="selected.length === 0" @click="addCompaniesSelected" />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { toRefs, computed, reactive, watch } from 'vue'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

dayjs.locale('it')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Rome')

export default {
  components: {
    VPagination
  },
  props: {
    scadute: {
      type: Boolean,
      default: () => false
    },
    archiviate: {
      type: Boolean,
      default: () => false
    },
    correnti: {
      type: Boolean,
      default: () => false
    },
    // questo booleano serve per gestire le eccezioni quando la selezione è per azienda
    exceptions: {
      type: Boolean,
      default: () => false
    },
    items: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    coid: {
      type: Number,
      default: () => -1
    },
    totalPagesItems: {
      type: Number,
      default: () => 1
    },
    pageSize: {
      type: Number,
      default: () => 1
    },
    totalElements: {
      type: Number,
      default: () => 1
    },
    pageNumber: {
      type: Number,
      default: () => 1
    },
    campaignTitle: {
      type: String,
      default: () => ''
    },
    //new
    companiesPreview: {
      type: Boolean,
      default: () => false
    },
    finalPreview: {
      type: Boolean,
      default: () => false
    },
    isEdit: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['update:correnti', 'update:archiviate', 'update:scadute', 'addCompaniesSelected', 'update:selected', 'searchByCoidOrTitle', 'update:page', 'update:remove'],
  setup(props, { emit }) {
    const state = reactive({
      counter: 0,
      searchCoidOrTitle: '',
      isOpen: props.exceptions? false : true,
      sortColumn: 'company',
      sortDirection: 'asc',
      searchQuery: '',
      currentPage: 1,
      itemsPerPage: props.items.length,
      filters: {
        order: {
          field: 'Ultima Creazione',
          asc: false,
          fieldInside: 'Data creazione',
          ascInside: true
        },
        search: []
      }
    })

    watch(() => state.searchCoidOrTitle, () => {
      emit('searchByCoidOrTitle', { search: state.searchCoidOrTitle })
    })

    const itemsDisplayed = computed(() => props.items)
    const selected = computed({
      get: () => props.selectedItems,
      set: (value) => emit('update:selected', value)
    })

    const pageNr = computed({
      get: () => Number(props.pageNumber),
      set: (value) => emit('update:page', value)
    })

    const filteredItems = computed(() => {
      const { sortColumn, sortDirection, searchQuery } = state
      const column = sortColumn
      const direction = sortDirection === 'asc' ? 1 : -1
      const lowercaseSearchQuery = searchQuery.toLowerCase()

      return props.items.filter(item =>
        ['coid', 'title', 'titolo', 'cid', 'company', 'status']
          .some(key => item?.[key]?.toString().toLowerCase().includes(lowercaseSearchQuery))
      ).sort((a, b) => (a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0) * direction)
    })

    const totalPages = computed(() => Math.ceil(filteredItems.value.length / state.itemsPerPage))

    const displayedItems = computed(() => {
      const startIndex = (state.currentPage - 1) * state.itemsPerPage
      const endIndex = startIndex + state.itemsPerPage
      return filteredItems.value.slice(startIndex, endIndex)
    })

    const previousPage = () => {
      if (state.currentPage > 1) {
        state.currentPage--
      }
    }

    const nextPage = () => {
      if (state.currentPage < totalPages.value) {
        state.currentPage++
      }
    }

    const sortTableBy = (column) => {
      if (state.sortColumn === column) {
        state.sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        state.sortColumn = column
        state.sortDirection = 'asc'
      }
    }

    const toggleSelection = (checked, o) => {
      if (!props.isEdit) {
        o.deactivated = true
        if (checked) {
          o.deactivated = false
          selected.value.push(o)
        } else {
          // search for coid and remove it
          const index = selected.value.findIndex(a => a.cid === o.cid)
          if (index > -1) {
            selected.value.splice(index, 1)
            o.deactivated = true
          }
        }
      } else {
        o.deactivated = !o.deactivated
      }

      emit('update:selected', selected.value)
    }

    const isChecked = (item) => {
      if (!props.isEdit) {
        return selected.value.some(a => a.cid === item.cid)
      } else {
        return !item.deactivated
      }
    }

    const addCompaniesSelected = () => {
      emit('addCompaniesSelected')
    }

    const formatDisplayString = (pageNumber, itemsPerPage, totalElements) => {
      const startIndex = (pageNumber - 1) * itemsPerPage + 1
      const endIndex = Math.min(startIndex + itemsPerPage - 1, totalElements)
      return `${startIndex} - ${endIndex} di ${totalElements}`
    }

    const getPageLabel = computed(() => {
      if (!props.finalPreview) {
        return formatDisplayString(pageNr.value, props.pageSize, props.totalElements)
      } else {
        return formatDisplayString(state.currentPage, state.itemsPerPage, filteredItems.value.length)
      }
    })

    const formatDate = (date) => {
      return dayjs(date).startOf('day').format('DD/MM/YYYY')
    }
    const correntiComputed = computed({
      get() {
        return props.correnti
      },
      set(value) {
        emit('update:correnti', value)
        state.counter++
      }
    })
    
    const archiviateComputed = computed({
      get() {
        return props.archiviate
      },
      set(value) {
        emit('update:archiviate', value)
        state.counter++
      }
    })
    const scaduteComputed = computed({
      get() {
        return props.scadute
      },
      set(value) {
        emit('update:scadute', value)
        state.counter++
      }
    })

    const removeItem = (coid, cid) => {
      emit('update:remove', { coid, cid })
    }

    return {
      correntiComputed,
      archiviateComputed,
      scaduteComputed,
      formatDate,
      addCompaniesSelected,
      itemsDisplayed,
      getPageLabel,
      pageNr,
      selected,
      isChecked,
      toggleSelection,
      sortTableBy,
      ...toRefs(state),
      displayedItems,
      totalPages,
      nextPage,
      previousPage,
      removeItem
    }
  }
}
</script>
<style lang="scss" scoped>
@use "../assets/scss/table.scss";
</style>
<style lang="scss" scoped>
@use "../assets/scss/projects";
</style>
<style lang="scss">
.removed-checkbox{
  input[type=checkbox]:checked {
    border-color: #ff6188!important;
    background: #ff6188!important;
  }
}</style>